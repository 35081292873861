<template>
  <nav class="navbar fixed-top navbar-expand-md navbar-expand-lg text-center">
    <!-- Mobile nav bar -->
    <button id="helmet" class="pl-0">
      <a href="#">
        <img src="../assets/spacehelmet.png" height="35" width="40" />
      </a>
      <span id="text">SPACE RABBIS</span>
    </button>
    <button
      class="px-0 navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <img class="img-fluid" src="../assets/hamburger_menu_icon.png" height="20" width="20" />
    </button>

    <!-- Navbar -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul id="nav" class="navbar-nav text-center mx-auto d-flex align-items-center">
        <li id="first-li" class="nav-item">
          <a class="navbar-brand d-md-block" href="#">
            <img id="logo" src="../assets/spacerabbi_logo.png" />
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="https://opensea.io/collection/spacerabbisfrontiercollection"
          >OPENSEA</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="https://discord.com/channels/890348873468493847/890665345130577971"
            target="_blank"
            rel="noopener"
          >DISCORD</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="https://twitter.com/SpaceRabbis"
            target="_blank"
            rel="noopener"
          >TWITTER</a>
        </li>
        <li>
          <a class="nav-link" href="#">WALLPAPERS</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar"
};
</script>e

<style scoped>
@media (min-width: 768px) {
  #logo {
    height: 10vmin;
    width: 8vmin;
  }
  #helmet {
    visibility: hidden;
    height: 0;
    width: 0;
  }
  .nav-item {
    margin-right: 30px;
  }
  .nav-link {
    margin-left: 2.5rem;
    font-weight: 800;
    letter-spacing: 2px;
  }
}
@media (max-width: 768px) {
  #logo {
    height: 24vmin;
    width: 18vmin;
  }
  .navbar-brand {
    visibility: hidden;
    height: 0;
    width: 0;
  }
  #first-li {
    height: 0;
  }
  .nav-link {
    font-weight: 700;
    letter-spacing: 1px;
  }
  #text {
    padding-left: 0.5rem;
    font-style: italic;
    font-weight: 800;
  }
}
.navbar,
#nav-bar {
  background-color: black;
  opacity: 0.9;
}
#logo {
  object-fit: contain;
  margin-right: 20px;
}
.nav-link {
  color: white;
  text-decoration: none;
}
#helmet {
  background-color: black;
  color: white;
  border: none;
  font-weight: 600;
}
</style>