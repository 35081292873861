<template>
  <div id="container">
    <b-row>
      <b-col>
        <b-row>
          <h1 class="pb-1 pt-md-4 pb-md-3">FOUNDERS</h1>
        </b-row>
        <b-container>
          <b-row>
            <b-col>
              <div>@jeremysteindesign</div>
              <div class="font-weight-bold">Jeremy Stein</div>
              <div>Artist & Creative Lead</div>
            </b-col>
            <b-col>
              <div>@Hodldorr</div>
              <div class="font-weight-bold">Evan Schports</div>
              <div>Community Manager</div>
            </b-col>
            <b-col>
              <div>@fikeplay</div>
              <div class="font-weight-bold">Akiva Weiss</div>
              <div>Marketing Lead</div>
            </b-col>
          </b-row>
        </b-container>
        <!-- <b-container>
          <b-row class="pt-4 pb-2">
            <b-col>
              <h4>@jeremystiendesign</h4>
            </b-col>
            <b-col>
              <h4>@Hodldorr</h4>
            </b-col>
            <b-col>
              <h4>@fikeplay</h4>
            </b-col>
          </b-row>
        </b-container>
        <b-container>
          <b-row class="pb-2">
            <b-col>
              <h4 class="font-weight-bold">Jeremy Stein</h4>
            </b-col>
            <b-col>
              <h4 class="font-weight-bold">Evan Schports</h4>
            </b-col>
            <b-col>
              <h4 class="font-weight-bold">Akiva Weiss</h4>
            </b-col>
          </b-row>
        </b-container>
        <b-container>
          <b-row class="pb-2">
            <b-col>
              <h4>Artist & Character Lead</h4>
            </b-col>
            <b-col>
              <h4>Community Manager</h4>
            </b-col>
            <b-col>
              <h4>Marketing Lead</h4>
            </b-col>
          </b-row>
        </b-container>-->
        <b-row class="pt-3 pb-md-3">
          <div class="accordion" role="tablist">
            <!-- <b-card no-body class="mb-1">
              <b-card-header
                v-b-toggle.accordion-3
                header-tag="header"
                class="py-3"
                role="tab"
              >Who are the leaders/team behind Space Rabbis?</b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text class="text">@Fikeplay – Founder & Chief Shiller</b-card-text>
                  <b-card-text class="text">
                    <a href="https://jeremysteindesign.com/art-direction" target="_blank">Jeremy S.</a> –
                    Founder & Artist/Character Designer
                  </b-card-text>
                  <b-card-text class="text">@Hodldor - Founder, Crypto Enthusiast & NFT Degen</b-card-text>
                  <b-card-text class="text">@MoWest – Developer</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
              <b-card-header
                v-b-toggle.accordion-8
                header-tag="header"
                class="py-3"
                role="tab"
              >When will Space Rabbis drop?</b-card-header>
              <b-collapse
                id="accordion-8"
                class="text px-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text class="text">Space Rabbis will go live on February 7th, @ 10AM EST.</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
              <b-card-header
                v-b-toggle.accordion-10
                header-tag="header"
                class="py-3"
                role="tab"
              >Is there a community wallet address? If so, what is it for?</b-card-header>
              <b-collapse
                id="accordion-10"
                class="text px-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    Yes! Our community wallet will be used to fund our ‘Alpha’ and invite
                    competitions. We will be depositing ETH to the community wallet for transparency.
                    <br />
                    <br />Wallet Address: 0x93BAcE648D43ADF1c5f7590741aE76240B595B05
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
              <b-card-header
                v-b-toggle.accordion-12
                header-tag="header"
                class="py-3"
                role="tab"
              >What are some of the long-term plans for Space Rabbis?</b-card-header>
              <b-collapse
                id="accordion-12"
                class="text px-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    The Space Rabbis Genesis is just the first launch that will help lay the
                    foundation for bigger things to come. There will be material advantages for all holders.
                    Please check out the Discord for more information on the competitions and rewards
                    available to the community.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
              <b-card-header
                v-b-toggle.accordion-13
                header-tag="header"
                class="py-3"
                role="tab"
              >What are the royalty fees?</b-card-header>
              <b-collapse
                id="accordion-13"
                class="text px-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    Sales on the secondary market will have a 7.5% fee. 50% of that will go
                    back into the community wallet, while the other 50% goes to the development team.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>-->
            <p class="pt-4 mb-0">
              Artist info: Jeremy Stein
              <br />
              <a href="https://jeremysteindesign.com" target="_blank">jeremysteindesign.com</a>
            </p>
            <p class="pt-0">
              <a
                href="https://www.instagram.com/jeremysteindesign/"
                target="_blank"
              >instagram.com/jeremysteindesign</a>
            </p>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SectionFive"
};
</script>

<style scoped>
@media (max-width: 576px) {
  #container {
    padding: 20px;
  }
  .card-header,
  .card-body {
    font-size: 4vw;
  }
}
@media (min-width: 576px) {
  #container {
    padding: 3rem 5rem;
  }
  .card-header,
  .card-body {
    font-size: 1rem;
  }
}
@media (min-width: 1260px) {
  .card-header,
  .card-body {
    font-size: 1.2rem;
  }
}
@media (min-width: 1440px) {
  .card-header,
  .card-body {
    font-size: 1.5rem;
  }
}
#container {
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
  text-align: center;
  background-color: white;
}
.card-header {
  background-color: rgba(251, 2, 182, 255);
  cursor: pointer;
  color: black;
}
h1 {
  text-align: center;
  font-weight: 800;
}
p {
  font-weight: 550;
}
li {
  list-style-type: none;
}
</style>