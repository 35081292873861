<template>
  <div id="app">
    <NavBar />
    <SpaceRabbi />
    <GefilteShip />
    <PublicLaunch />
    <ShouldIBuy />
    <Roadmap />
    <FAQ />
    <Footer />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import SpaceRabbi from "./components/SpaceRabbi.vue";
import GefilteShip from "./components/GefilteShip.vue";
import PublicLaunch from "./components/PublicLaunch.vue";
import ShouldIBuy from "./components/ShouldIBuy.vue";
import Roadmap from "./components/Roadmap.vue";
import FAQ from "./components/FAQ.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    SpaceRabbi,
    GefilteShip,
    PublicLaunch,
    ShouldIBuy,
    Roadmap,
    FAQ,
    Footer
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@600;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Work+Sans");

@media (min-width: 768px) {
  #app {
    min-height: 10vw;
    background-image: url("./assets/spacerabbi_space_bg.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
  }
}
#app {
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color: #2c3e50;
  width: 100%;
  overflow: hidden;
}
body {
  background-color: black;
}
</style>
