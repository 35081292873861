<template>
<b-container fluid id="container">
    <b-row>
      <CountDown></CountDown>
    </b-row>
    <b-row class="justify-content-center pt-2">
      <b-col col lg="5" md="6" sm="8">
        <b-img-lazy src="../assets/spacerabbi_landing4.gif" fluid alt="Responsive image"></b-img-lazy>
      </b-col>
    </b-row>
</b-container>
</template>

<script>
import CountDown from './CountDown.vue'
export default {
  name: 'SpaceRabbi',
  components: {
    CountDown
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  #container {
    background-image: url('../assets/spacerabbi_space_bg.jpg');
  }
  #container {
      padding-top: 2rem;
    }
}
@media (max-width: 768px) { 
    
}
@media (min-width: 768px) { 
    #container {
      padding-top: 80px;
    }
}

#outer {
  text-align: center;
}
</style>
