<template>
  <b-container fluid id="container">
    <b-row>
      <b-col>
        <b-row class="pb-1">
          <h3>PUBLIC LAUNCH, TBD</h3>
          <!-- <h5>DECEMBER 26TH @6PM EST.</h5> -->
        </b-row>
        <b-row>
          <h3 class="pb-2">
            <span>
              <a
                href="https://discord.com/channels/890348873468493847/890665345130577971"
                target="_blank"
                rel="noopener"
              >JOIN OUR DISCORD</a>
            </span> TO STAY UPDATED
          </h3>
          <h5>MINT PRICE: .06 ETH</h5>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "SectionTwo"
};
</script>

<style scoped>
@media (max-width: 768px) {
  #container {
    padding: 20px 40px;
    background-image: url("../assets/spacerabbi_space_bg.jpg");
  }
  h3,
  h5 {
    font-size: 1.2rem;
    font-weight: 600;
  }
}
@media (min-width: 768px) {
  #container {
    padding: 5rem;
  }
  h3,
  h5 {
    font-weight: 600;
  }
}
#container {
  text-align: center;
}
h3 {
  color: white;
}
h5 {
  color: rgba(251, 2, 182, 255);
}
.link {
  color: white;
  text-decoration: none;
}
</style>