<template>
  <b-container fluid class="px-4" id="container">
    <b-row>
      <b-col id="countdown-title" class="pb-2" cols="12">
        <h1>ALL ABOARD THE GEFILTE SHIP!</h1>
      </b-col>
      <!-- <b-col id="countdown-subtitle" class="pb-2" cols="12">
        <h2>FRONTIER COLLECTION LAUNCHING FEB. 7TH, 2022</h2>
      </b-col>-->
    </b-row>
    <!-- <b-row class="gx-2 justify-content-center text-center flex-nowrap p-2">
            <b-col col xl="1" lg="2" md="2" sm="2">
                <b-row>
                    <b-col>
                    <b-card
                        :title=days
                        class="square text-center align-items-center"
                    >
                    </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card class="text text-center border-0">
                        <b-card-text>Days</b-card-text>
                    </b-card>
                    </b-col>
                </b-row>
                </b-col>
                <b-col col xl="1" lg="2" md="2" sm="2">
                <b-row>
                    <b-col>
                    <b-card
                        :title=hours
                        class="square text-center align-items-center"
                    >
                    </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card class="text text-center border-0">
                            Hours
                        </b-card>
                    </b-col>
                </b-row>
                </b-col>
                <b-col col xl="1" lg="2" md="2" sm="2">
                <b-row>
                    <b-col>
                    <b-card
                        :title=minutes
                        class="square text-center align-items-center"
                    >
                    </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card class="text text-center border-0">
                        <b-card-text>Minutes</b-card-text>
                    </b-card>
                    </b-col>
                </b-row>
                </b-col>
                <b-col col xl="1" lg="2" md="2" sm="2">
                <b-row>
                    <b-col>
                    <b-card
                        :title=seconds
                        class="square text-center align-items-center"
                    >
                    </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card class="text text-center border-0">
                        <b-card-text>Seconds</b-card-text>
                    </b-card>
                    </b-col>
                </b-row>
            </b-col>
    </b-row>-->
  </b-container>
</template>

<script>
export default {
  name: "CountDown",
  props: {
    date: {
      type: Number
    }
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000)
    };
  },
  mounted() {
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  computed: {
    modifiedDate: function() {
      return Math.trunc(Date.parse(this.date) / 1000);
    },
    seconds() {
      var seconds = (this.date - this.now) % 60;
      if (seconds.toString().length <= 1) {
        return "0" + seconds.toString();
      }
      return seconds.toString();
    },
    minutes() {
      var min = Math.trunc((this.date - this.now) / 60) % 60;
      if (min.toString().length <= 1) {
        return "0" + min.toString();
      }
      return min.toString();
    },
    hours() {
      var hours = Math.trunc((this.date - this.now) / 60 / 60) % 24;
      if (hours.toString().length <= 1) {
        return "0" + hours.toString();
      }
      return hours.toString();
    },
    days() {
      var days = Math.trunc((this.date - this.now) / 60 / 60 / 24);
      if (days.toString().length <= 1) {
        return "0" + days.toString();
      }
      return days.toString();
    }
  },
  filters: {
    two_digits: function(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    }
  }
};
</script>

<style  scoped>
@media (max-width: 576px) {
  .card {
    width: 5rem;
  }
}
@media (max-width: 576px) {
  h4.card-title {
    font-size: 2rem;
  }
  .text .card-body {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .square {
    padding-top: 12.5%;
    padding-bottom: 12.5%;
  }
  h4.card-title {
    font-size: 2.5rem;
  }
  .text .card-body {
    font-size: 1.25rem;
  }
}
@media (min-width: 992px) {
  .square {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (min-width: 1080px) {
  .text .card-body {
    font-size: 1.5rem;
  }
}

#container {
  width: 100%;
}

#countdown-title h1 {
  color: white;
  /* color: rgba(251,2,182,255); */
  font-weight: 800;
  text-align: center;
}
#countdown-subtitle h2 {
  color: rgba(251, 2, 182, 255);
  font-weight: 600;
  text-align: center;
}
.block {
  display: flex;
  flex-direction: column;
  margin: 1vw;
  text-align: center;
}
.block2 {
  display: flex;
  flex-direction: column;
}
.text .card-body {
  /* font-size: 1.5rem; */
  color: white;
  background-image: url("../assets/spacerabbi_space_bg.jpg");
  padding: 0rem;
  padding-top: 0.5rem;
}
.card {
  word-wrap: normal;
}

h4.card-title {
  margin-bottom: 0rem;
  font-weight: 600;
}
</style>