<template>
    <b-container fluid id="container">
        <b-row cols="12" class="justify-content-center">
            <b-col md="10" lg="8">
                <b-row>
                    <h1 class="pb-3">SHOULD I BUY A SPACE RABBI?</h1>
                </b-row>
                <b-row>
                    <p>You don’t have to be a member of the tribe to own a Space Rabbi. Whether you're a Bar Mitzvah boi or not, you’re welcome in this community as long as you’re a <span id="italic">mensch</span>.</p>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'SectionThree'
}
</script>

<style scoped>
@media (max-width: 576px) { 
    #container {
        padding: 20px;
    }
    p {
        font-size: 4vw;
    }
 }
 @media (min-width: 576px) { 
    #container {
        padding: 5rem;
    } 
    p {
        font-size: 1.2rem;
    }
}
 @media (min-width: 1360px) { 
    #container {
        padding: 5rem;
    } 
    p {
        font-size: 1.5rem;
    }
}
#container {
    background-color: rgba(251,2,182,255);
    text-align: center;
}
h1 {
    font-weight: 800;
    color: white;
}
p {
    color: black;
}
#italic {
    font-style: italic;
}
</style>