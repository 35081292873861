<template>
  <b-container fluid id="container">
    <b-row class="justify-content-center">
      <b-col md="10" lg="8">
        <h1 class="py-2 py-sm-3 pb-md-5 white-text">TROUBLE GETTING STARTED?</h1>
        <p
          class="white-text"
        >Are you interested in this, but the transition into the world of crypto makes you queasy? We’ve set up a toolkit on our Discord server to help guide people from their coin purchase to their NFT purchase. There are also channels dedicated to more nuanced topics for those interested in exploring the world of DeFi.</p>
      </b-col>
    </b-row>
    <b-row id="image-row" class="pt-4 pt-md-4 justify-content-center" cols="12">
      <div class="icon col-6">
        <b-col>
          <a
            href="https://discord.com/channels/890348873468493847/890665345130577971"
            target="_blank"
            rel="noopener"
          >
            <b-img src="../assets/discord_icon.png" fluid alt="Discord icon"></b-img>
          </a>
        </b-col>
      </div>
      <div class="icon col-6">
        <b-col>
          <a href="https://twitter.com/SpaceRabbis" target="_blank" rel="noopener">
            <b-img src="../assets/twitter_icon.png" fluid alt="Twitter icon"></b-img>
          </a>
        </b-col>
      </div>
    </b-row>
    <b-row class="pt-5"></b-row>
    <b-row>
      <b-col>
        <p id="copyright" class="white-text">
          <a href="mailto:SpaceRabbis@gmail.com">Get in touch</a>
        </p>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <p id="copyright" class="white-text">© 2022 Space Rabbis</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "SectionSix"
};
</script>

<style scoped>
@media (max-width: 576px) {
  p {
    font-size: 4vw;
  }
}
@media (min-width: 576px) {
  p {
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  #container {
    padding: 20px;
    background-image: url("../assets/spacerabbi_space_bg.jpg");
  }
  .icon {
    width: 7rem;
    height: 4rem;
  }
}
@media (min-width: 768px) {
  #container {
    padding-top: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  p {
    font-size: 1.25rem;
  }
  img {
    max-height: 75%;
    max-width: 75%;
  }
  .icon {
    width: 10rem;
    height: 1rem;
  }
  #copyright {
    padding-top: 3rem;
  }
}
#container {
  text-align: center;
}
h1 {
  font-weight: 800;
}
.white-text {
  color: white;
}
#copyright {
  text-align: center;
}
p {
  text-align: center;
}
</style>