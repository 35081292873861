<template>
  <div>
    <b-container fluid id="container">
      <b-row>
        <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
          <h1 class="pb-2 pb-lg-4">KEEPING THE NFT SPACE WEIRD</h1>
          <p>
            Welcome to Space Rabbis, a generative NFT collection of 1,800 unique
            Rabbis gone crypto featuring the first Jews housed on the
            Blockchain. The Space Rabbis are on a journey to learn and share
            everything there is to know about this world and the next, venturing
            where no Rabbi has gone before. Their sights are set on the ethereal
            Web 3.0, and you can bet your bottom Bitcoin this project will be
            certified Kosher!
            <br />
            <br />We have already sold out our Frontier Space Rabbis collection,
            which can be purchased on the secondary market via
            <a
              href="https://opensea.io/collection/spacerabbisfrontiercollection"
            >OpenSea</a>.
            <br />
            <br />Your Space Rabbi NFT is your
            all-access pass to an exclusive community of like-minded crypto
            enthusiasts, giveaways, competitions and a vibrant community.
            <br />
            <br />Tell your friends, tell the mayor, tell your Bubbe...all are
            welcome as we prepare for liftoff.
          </p>
        </b-col>
        <b-col class="col-12 col-sm-6 col-md-6 col-lg-6 text-right">
          <b-img src="../assets/spacerabbi_grid.jpg" fluid alt="Space Rabbi Grid"></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SectionOne"
};
</script>

<style scoped>
@media (max-width: 576px) {
  #container {
    padding: 20px;
  }
  h1 {
    font-size: 1.25rem;
  }
  p {
    font-size: 4vw;
  }
}
@media (min-width: 576px) {
  #container {
    padding: 5rem;
  }
  h1 {
    font-size: 1.2rem;
  }
  p {
    font-size: 0.6rem;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 1.6rem;
  }
  p {
    font-size: 0.9rem;
  }
}
@media (min-width: 1000px) {
  h1 {
    font-size: 1.8rem;
  }
  p {
    font-size: 1.25rem;
  }
}
@media (min-width: 1260px) {
  #container {
    padding: 6rem;
  }
  h1 {
    font-size: 2.2rem;
  }
  p {
    font-size: 1.2rem;
  }
}
@media (min-width: 1440px) {
  #container {
    padding: 7rem;
  }
  h1 {
    font-size: 2.8rem;
  }
  p {
    font-size: 1.6rem;
  }
}
#container {
  width: 100%;
  background-color: white;
}
h1 {
  font-weight: 800;
}
</style>
