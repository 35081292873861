<template>
  <b-container fluid id="container">
    <b-row cols="12" class="justify-content-center">
      <b-col md="10" lg="8">
        <b-row>
          <h1 class="pb-1 py-md-4 pink-text">ROADMAP</h1>
        </b-row>
        <b-row>
          <b-col>
            <p class="sub-header">
              Instead of a roadmap that makes for good marketing,
              Space Rabbis will build utility that delivers actual value to the community.
            </p>
            <p class="sub-header">
              Owning a Space Rabbi entitles you to a vote on what utility we should build for the
              community. We will set up a Space Rabbis Treasury seeded with 25% of the funds
              generated via the public sale. Holders can vote to use the Treasury’s funds on
              whatever they think will be most valuable to the community.
            </p>
            <p class="sub-header">
              Our community is collaborative, supportive, and creative! What do you want to
              see included in the Space Rabbis NFT? Nothing is off the table!
            </p>
          </b-col>
        </b-row>
        <!-- <b-row>
          <h1 class="pb-1 py-md-4">ROADMAP</h1>
        </b-row>-->
        <!-- <b-row class="pt-3">
          <b-col>
            <h3 class="sub-header">COME FOR THE ART, STAY FOR THE RIDE.</h3>
            <h3
              class="sub-header pink-text"
            >AND BY THE RIDE WE MEAN THE TESLA WE’RE GIVING AWAY AT 100% MINT!!!</h3>
            <h3 class="sub-header text-center">L'CHAIM!</h3>
          </b-col>
        </b-row>-->
        <!-- <b-row class="py-2">
          <b-card>
            <b-row class="align-items-center">
              <b-col class="progress-circle col-12 col-md-3 col-lg-1">
                <Progress strokeColor="#fb02b4" :radius="50" :strokeWidth="10" value="10">
                  <div class="content">
                    <h3 class="percent">10%</h3>
                  </div>
                </Progress>
              </b-col>
              <b-col class="col-12 col-md-9 col-lg-11 text-left">
                <b-card-body>
                  <b-card-text>
                    <p>• 10 NFT holders will receive hoodies.</p>
                  </b-card-text>
                  <b-card-text>
                    <p>• We will be making a $5,000 donation to the Anti Defamation League.</p>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <b-row class="align-items-center">
              <b-col class="progress-circle col-12 col-md-3 col-lg-1">
                <Progress strokeColor="#fb02b4" :radius="50" :strokeWidth="10" value="50">
                  <div class="content">
                    <h3 class="percent">50%</h3>
                  </div>
                </Progress>
              </b-col>
              <b-col class="col-12 col-md-9 col-lg-11 text-left">
                <b-card-body>
                  <b-card-text>
                    <p>• A $10,000 donation will be made to a TBD charity.</p>
                  </b-card-text>
                  <b-card-text>
                    <p>• Merch Shop is open for business. 100% of merch profits will be added to a Community Wallet. Space Rabbis holders will decide how to distribute the funds.</p>
                  </b-card-text>
                  <b-card-text>
                    <p>• One lucky winner will receive a custom Space Rabbi drawn for them by the artist.</p>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <b-row class="align-items-center">
              <b-col class="progress-circle col-12 col-md-3 col-lg-1">
                <Progress strokeColor="#fb02b4" :radius="50" :strokeWidth="10" value="100">
                  <div class="content">
                    <h3 class="percent">100%</h3>
                  </div>
                </Progress>
              </b-col>
              <b-col class="col-12 col-md-9 col-lg-11 text-left">
                <b-card-body>
                  <b-card-text>
                    <p>• One Mega Raffle for a Tesla Model 3 for all holders of a Space Rabbi (snapshot for eligible Rabbis will be taken 10 days after mint at 6PM EST).</p>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-row>-->
        <!-- <b-row class="pt-2 pb-3 pb-md-5 justify-content-center">
          <b-col md="10" lg="8">
            <h3
              id="bottom-sub-header"
              class="sub-header"
            >AS OUR SPACE RABBIS HAVE TAUGHT US, EVEN THE SKY'S NOT THE LIMIT. WE'RE PLANNING SOMETHING SPECIAL FOR A SEASON 2 COLLECTION THAT WILL KNOCK THE YARMULKE OFF YOUR HEAD.</h3>
          </b-col>
        </b-row>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import Progress from "easy-circular-progress";

export default {
  name: "SectionFour"
  //   components: {
  //     Progress
  //   }
};
</script>

<style scoped>
@media (max-width: 576px) {
  p {
    font-size: 4vw;
  }
}
@media (min-width: 576px) {
  p {
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  #container {
    padding: 20px;
    background-image: url("../assets/spacerabbi_space_bg.jpg");
  }
  .sub-header {
    font-weight: 500;
  }
  .progress-circle {
    text-align: center;
    padding-bottom: 1rem;
  }
  h1 {
    font-weight: 600;
  }
}
@media (min-width: 768px) {
  #container {
    padding: 5rem;
  }
  .sub-header {
    font-weight: 600;
  }
  .progress-circle {
    text-align: center;
  }
  h1 {
    text-align: center;
    font-weight: 800;
  }
}
#container {
  text-align: center;
}
.card {
  background-color: transparent;
}
.card-body {
  padding: 1rem 0rem;
}
.percent {
  color: white;
  margin-bottom: 0rem;
}
h1 {
  font-weight: 800;
  color: white;
}
h2,
.card-text {
  color: white;
}
.sub-header {
  color: white;
}
.pink-text {
  color: rgba(251, 2, 182, 255);
}
</style>